<template lang="pug">
  div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
    b-row.content-scrollable-sticky.m-0.justify-content-center(style="margin-top: -12px !important;")
      b-col#nav-sticky-horizontal.content-scrollable-sticky-inner(cols='12' style='padding: 0 !important; height: 100% !important')
        ul.d-flex
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'all' ? 'current' : ''" @click="tabActive = 'all'")
              span Tous les points ({{ getCountDataByStatus("all") }})
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'building' ? 'current' : ''" @click="tabActive = 'building'")
              span Bâtiments ({{ getCountDataByStatus("Bâtiments") }})
          li
            a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'vehicle' ? 'current' : ''" @click="tabActive = 'vehicle'")
              span Véhicules ({{ getCountDataByStatus("Véhicules") }})
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingStorageLocationsList || isLoadingStorageLocationsListArchived || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des points de stockage...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows"  :tagguerButton="tagguerButton")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-storage-location-content(ref='sidebarStorageLocationContent' @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import { storageLocationTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarStorageLocationContent from "@/components/purchase/stock/SidebarStorageLocationContent.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarStorageLocationContent,
    SyncGrid,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "storageLocation",
      uid: "storageLocations",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un point de stockage",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "label",
        headerText: "Nom",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: false,
        visible: true,
      },
      {
        type: "string",
        field: "address",
        headerText: "Adresse",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "zipCode",
        headerText: "CP",
        width: 90,
        maxWidth: 90,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "city",
        headerText: "Ville",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },

      {
        type: "string",
        field: "phoneNumber",
        headerText: "Téléphone",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "email",
        headerText: "Email",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "isLoadingStorageLocationsList",
      "isLoadingStorageLocationsListArchived",
    ]),
    dataOrigine() {
      if (this.archive) {
        return this.$store.getters[this.name + "sListArchived"];
      } else {
        return this.$store.getters[this.name + "sList"];
      }
    },
    dataFiltered() {
      if (this.tabActive == "all") {
        if (this.archive) {
          return this.$store.getters[this.name + "sListArchived"];
        } else {
          return this.$store.getters[this.name + "sList"];
        }
      } else if (this.tabActive == "building") {
        if (this.archive) {
          return this.$store.getters[this.name + "sListArchived"].filter(
            (elem) => elem.storageLocationType === 0
          );
        } else {
          return this.$store.getters[this.name + "sList"].filter(
            (elem) => elem.storageLocationType === 0
          );
        }
      } else if (this.tabActive == "vehicle") {
        if (this.archive) {
          return this.$store.getters[this.name + "sListArchived"].filter(
            (elem) => elem.storageLocationType === 1
          );
        } else {
          return this.$store.getters[this.name + "sList"].filter(
            (elem) => elem.storageLocationType === 1
          );
        }
      }
    },
  },
  async created() {
    await this.getStorageLocations({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  mounted() {
    this.viewMap = false;
  },
  methods: {
    ...mapActions(["getStorageLocations", "getStorageLocationsArchived"]),
    storageLocationTypeTranslate,
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({ path: "/new-storage-location" });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getStorageLocationsArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getStorageLocations({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      if (!this.archive) {
        this.$refs["sidebarStorageLocationContent"].getStorageLocationDetails(
          event.rowData.id
        );
      } else {
        this.$refs[
          "sidebarStorageLocationContent"
        ].getStorageLocationArchivedDetails(event.rowData.id, this.archive);
      }
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else if (status == "Bâtiments") {
        return this.dataOrigine.filter((elem) => elem.storageLocationType == 0)
          .length;
      } else if (status == "Véhicules") {
        return this.dataOrigine.filter((elem) => elem.storageLocationType == 1)
          .length;
      }
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
