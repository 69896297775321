var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[(
      _vm.isLoadingStorageLocation ||
      _vm.isUpdatingStorageLocation ||
      _vm.isDeletingStorageLocation
    )?_c('div',{staticClass:"h-100"},[_vm._m(0)]):_c('div',{staticClass:"h-100"},[_c('div',{staticClass:"sidebar-header py-1 px-2"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.storageLocation.label))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.storageLocation.storageLocationType),expression:"storageLocation.storageLocationType"}]},[_vm._v("Type : "+_vm._s(_vm.storageLocationTypeTranslate(_vm.storageLocation.storageLocationType)))])]),_c('feather-icon',{staticClass:"close-icon cursor-pointer",attrs:{"icon":"XIcon","size":"24"},on:{"click":function($event){$event.stopPropagation();return _vm.closeSidebar.apply(null, arguments)}}})],1),_c('vs-divider',{staticClass:"m-0"}),_c('div',{staticClass:"sidebar-action py-05 px-2"},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.archived)?_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.restoreStorageLocationLocal(
              _vm.storageLocation.id,
              _vm.storageLocation.label
            )}}},[_c('feather-icon',{attrs:{"icon":"ArrowDownIcon"}}),_vm._v(" Restaurer ")],1):_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"primary","disabled":_vm.archived},on:{"click":function($event){return _vm.editStorageLocation(_vm.storageLocation.id)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" Modifier ")],1)],1),_c('b-button-group',[(_vm.archived)?_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteStorageLocationLocal(
              _vm.storageLocation.id,
              _vm.storageLocation.label
            )}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_vm._v(" Supprimer définitivement ")],1):_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.archiveStorageLocationLocal(
              _vm.storageLocation.id,
              _vm.storageLocation.label
            )}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}})],1)],1)],1),_c('vs-divider',{staticClass:"m-0"}),_c('div',{staticClass:"sidebar-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.storageLocation.email || _vm.storageLocation.phoneNumber),expression:"storageLocation.email || storageLocation.phoneNumber"}]},[_c('h4',{staticClass:"mb-1 text-primary"},[_c('feather-icon',{attrs:{"icon":"PhoneCallIcon"}}),_vm._v(" Coordonnées ")],1),_c('ul',{staticClass:"listTab"},[_c('li',[_c('span',[_vm._v("Adresse e-mail")]),_c('span',[_vm._v(_vm._s(_vm.storageLocation.email))])]),_c('li',[_c('span',[_vm._v("Téléphone")]),_c('span',[_vm._v(_vm._s(_vm.storageLocation.phoneNumber))])])]),_c('vs-divider')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.storageLocation.address ||
          _vm.storageLocation.addressComplement ||
          _vm.storageLocation.zipCode ||
          _vm.storageLocation.city
        ),expression:"\n          storageLocation.address ||\n          storageLocation.addressComplement ||\n          storageLocation.zipCode ||\n          storageLocation.city\n        "}]},[_c('h4',{staticClass:"mb-1 text-primary"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon"}}),_vm._v(" Adresse ")],1),_c('p',{staticClass:"paraphbox"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.storageLocation.address),expression:"storageLocation.address"}]},[_vm._v(_vm._s(_vm.storageLocation.address)),_c('br')]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.storageLocation.addressComplement),expression:"storageLocation.addressComplement"}]},[_vm._v(_vm._s(_vm.storageLocation.addressComplement)),_c('br')]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.storageLocation.zipCode || _vm.storageLocation.city),expression:"storageLocation.zipCode || storageLocation.city"}]},[_vm._v(_vm._s(_vm.storageLocation.zipCode)+" "+_vm._s(_vm.storageLocation.city)),_c('br')])]),_c('vs-divider')],1),(_vm.storageLocation.latitude && _vm.storageLocation.longitude)?_c('div',{staticClass:"w-full d-flex justify-content-between mt-1"},[_c('GmapMap',{ref:"gmap",staticClass:"w-100 card-map",staticStyle:{"height":"220px"},attrs:{"center":{
            lat: _vm.storageLocation.latitude,
            lng: _vm.storageLocation.longitude,
          },"zoom":15}},[_c('GmapMarker',{attrs:{"position":{
              lat: _vm.storageLocation.latitude,
              lng: _vm.storageLocation.longitude,
            },"clickable":true,"draggable":true},on:{"click":function($event){_vm.center = {
                lat: _vm.storageLocation.latitude,
                lng: _vm.storageLocation.longitude,
              }}}})],1)],1):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center flex-center h-100"},[_c('div',{staticClass:"loading-bg-inner"},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"outer"}),_c('div',{staticClass:"middle"}),_c('div',{staticClass:"inner"})])]),_c('div',{staticStyle:{"margin-top":"7rem"}},[_vm._v(" Chargement des détails du point de stockage... ")])])}]

export { render, staticRenderFns }