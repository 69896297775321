<template>
  <div class="h-100">
    <div
      v-if="
        isLoadingStorageLocation ||
        isUpdatingStorageLocation ||
        isDeletingStorageLocation
      "
      class="h-100"
    >
      <div class="text-center flex-center h-100">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div style="margin-top: 7rem">
          Chargement des détails du point de stockage...
        </div>
      </div>
    </div>

    <div v-else class="h-100">
      <div class="sidebar-header py-1 px-2">
        <div>
          <h3>{{ storageLocation.label }}</h3>
          <small v-show="storageLocation.storageLocationType"
            >Type :
            {{
              storageLocationTypeTranslate(storageLocation.storageLocationType)
            }}</small
          >
        </div>
        <feather-icon
          icon="XIcon"
          size="24"
          @click.stop="closeSidebar"
          class="close-icon cursor-pointer"
        ></feather-icon>
      </div>

      <vs-divider class="m-0" />

      <div class="sidebar-action py-05 px-2">
        <div class="d-flex align-items-center">
          <b-button
            v-if="archived"
            variant="warning"
            class="btn-icon"
            @click="
              restoreStorageLocationLocal(
                storageLocation.id,
                storageLocation.label
              )
            "
          >
            <feather-icon icon="ArrowDownIcon" /> Restaurer
          </b-button>
          <b-button
            v-else
            class="btn-icon"
            variant="primary"
            @click="editStorageLocation(storageLocation.id)"
            :disabled="archived"
          >
            <feather-icon icon="EditIcon" /> Modifier
          </b-button>
        </div>
        <b-button-group>
          <b-button
            v-if="archived"
            variant="outline-danger"
            class="btn-icon"
            @click="
              deleteStorageLocationLocal(
                storageLocation.id,
                storageLocation.label
              )
            "
          >
            <feather-icon icon="TrashIcon" /> Supprimer définitivement
          </b-button>
          <b-button
            v-else
            variant="outline-danger"
            class="btn-icon"
            @click="
              archiveStorageLocationLocal(
                storageLocation.id,
                storageLocation.label
              )
            "
          >
            <feather-icon icon="ArchiveIcon" />
          </b-button>
        </b-button-group>
      </div>

      <vs-divider class="m-0" />

      <div class="sidebar-content">
        <div v-show="storageLocation.email || storageLocation.phoneNumber">
          <h4 class="mb-1 text-primary">
            <feather-icon icon="PhoneCallIcon" /> Coordonnées
          </h4>
          <ul class="listTab">
            <li>
              <span>Adresse e-mail</span>
              <span>{{ storageLocation.email }}</span>
            </li>
            <li>
              <span>Téléphone</span>
              <span>{{ storageLocation.phoneNumber }}</span>
            </li>
          </ul>
          <vs-divider />
        </div>
        <div
          v-show="
            storageLocation.address ||
            storageLocation.addressComplement ||
            storageLocation.zipCode ||
            storageLocation.city
          "
        >
          <h4 class="mb-1 text-primary">
            <feather-icon icon="MapPinIcon" /> Adresse
          </h4>
          <p class="paraphbox">
            <span v-show="storageLocation.address"
              >{{ storageLocation.address }}<br
            /></span>
            <span v-show="storageLocation.addressComplement"
              >{{ storageLocation.addressComplement }}<br
            /></span>
            <span v-show="storageLocation.zipCode || storageLocation.city"
              >{{ storageLocation.zipCode }} {{ storageLocation.city }}<br
            /></span>
          </p>
          <vs-divider />
        </div>
        <div
          class="w-full d-flex justify-content-between mt-1"
          v-if="storageLocation.latitude && storageLocation.longitude"
        >
          <GmapMap
            class="w-100 card-map"
            ref="gmap"
            :center="{
              lat: storageLocation.latitude,
              lng: storageLocation.longitude,
            }"
            :zoom="15"
            style="height: 220px"
          >
            <GmapMarker
              :position="{
                lat: storageLocation.latitude,
                lng: storageLocation.longitude,
              }"
              :clickable="true"
              :draggable="true"
              @click="
                center = {
                  lat: storageLocation.latitude,
                  lng: storageLocation.longitude,
                }
              "
            >
            </GmapMarker>
          </GmapMap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import { storageLocationTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: false,
      archived: false,
      storageLocation: {},
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingStorageLocation",
      "storageLocationsList",
      "isLoadingStorageLocation",
      "isDeletingStorageLocation",
    ]),
  },
  methods: {
    storageLocationTypeTranslate,
    ...mapActions([
      "deleteStorageLocations",
      "isArchivingStorageLocation",
      "archiveStorageLocations",
      "getStorageLocationById",
      "getStorageLocationArchivedById",
      "restoreStorageLocations",
    ]),

    getStorageLocationArchivedDetails(id, archived) {
      this.archived = archived;
      if (id > 0) {
        this.getStorageLocationArchivedById({ storageLocationId: id }).then(
          (res) => {
            this.storageLocation = res;
          }
        );
      } else {
        this.storageLocation = {};
      }
    },
    getStorageLocationDetails(id) {
      this.archived = false;
      if (id > 0) {
        this.getStorageLocationById({ storageLocationId: id }).then((res) => {
          this.storageLocation = res;
        });
      } else {
        this.storageLocation = {};
      }
    },

    async editStorageLocation(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-storage-location",
        params: {
          id: id,
          title: "Modifier : " + this.storageLocation.label,
          tips: "Modifier le point de stockage : " + this.storageLocation.label,
        },
      });
    },

    archiveStorageLocationLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveStorageLocations({ storageLocationIds: [id] });
            this.closeSidebar();
            this.$emit("refreshGrid");
          }
        });
    },
    restoreStorageLocationLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title: 'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreStorageLocations({ storageLocationIds: [id] });
            this.closeSidebar();
            this.$emit("refreshGrid");
          }
        });
    },
    deleteStorageLocationLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: 'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteStorageLocations({ storageLocationIds: [id] });
            this.closeSidebar();
            this.$emit("refreshGrid");
          }
        });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>
